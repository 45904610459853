<template>
    <div class="d-flex flex-column align-items-center" >
        <logos-container center/>
        <h1 class="oops-page__title">
            {{ $t('Oh no! Something went wrong') }}
        </h1>
        <p class="oops-page__text">
            {{ $t(message) }}
        </p>
        <a href="/">
            <button class="mt-5 oops-page__btn">
                {{ $t("Back to home page") }}
            </button>
        </a>
    </div>
</template>
<script>
import LogosContainer from '@/components/ui/LogosContainer.vue';
export default {
    name: "oops",
    components: { LogosContainer },
    data(){
        return {
            message: "There was a problem processing your request."
        }
    },
    mounted() {
        if(this.$route.query.message) {
            this.message = this.$route.query.message
        }
    }
}
</script>
<style lang="scss">
.oops-page {

    &__title {
        margin-top: 50px;
        margin-bottom: 30px;
        font-weight: 300;
        color: #787F84;
        text-align: center;
        @media (max-width:450px) {
            font-size: 1.2rem;            
        }
    }

    &__text {
        font-size: 1.3rem;
        font-weight: 300;
        color: #787F84;
        max-width: 400px;
        text-align: center;
        line-height: 25px;
        @media (max-width:450px) {
            font-size: 1rem;            
        }
    }

    &__btn {
        background:none;
        padding: 4px 12px;
        width: 250px;
        border: none;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>