<template>
    <div class="logos-container" :class="{'center' : center}">
        <template v-if="logoType !== 'accounts'">
            <img class="logos-container__logo-img" :src="require(`@/assets/images/logos/${logosRoutes[logoType]}.svg`)" alt="envia-logo" />
            <div v-if="showAccountsLogo" class="logos-container__divider"></div>
        </template>
        <router-link v-if="showAccountsLogo" to="/" class="logos-container__link" :class="{ 'logos-container__link--disabled': disableAccountsLink }">
            <img
                src="@/assets/images/logos/accounts-logo.svg"
                class="logos-container__accounts-logo"
                alt="Accounts logo"
            />
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props:{
        center: {
            type: Boolean,
            default: false,
            required: false,
        }
    },
    data() {
        return {
            logosRoutes: {
                envia: 'envia',
                ecartPay: 'ecart-pay-color',
                ecartSeller: 'ecart-seller-color',
                fulfillment: 'fulfillment',
                fulfillmentMibox: 'fulfillment-mibox',
                returns: 'returns-color',
                wms: 'wms',
            },
            disableAccountsLink: false,
        }
    },
    computed: {
        ...mapGetters('ui', ['sites']),
        showAccountsLogo() {
            const { site_id } = this.$route.query;

            return site_id !== '66317a0b6c6ae21e0bfb5c9b';
        },
        logoType() {
            const { site_id } = this.$route.query;
            const site = this.sites.find(site => site.site_id === site_id);

            if (site_id === '66317a0b6c6ae21e0bfb5c9b') {
                return 'fulfillmentMibox';
            }

            if (site_id && site) {
                const logoTypes = {
                    'Ecart Seller': 'ecartSeller',
                    'Ecart Pay': 'ecartPay',
                    'Returns': 'returns',
                    'Fulfillment': 'fulfillment',
                    'Envia': 'envia',
                    'WMS': 'wms',
                }

                if (logoTypes[site.name]) {
                    return logoTypes[site.name];
                }
            }

            return 'accounts'
        },
    },
    mounted() {
        const { redirect_url } = this.$route.query
        
        if (redirect_url) {
            this.disableAccountsLink = true;
        }
    },
}
</script>

<style lang="scss">
.logos-container {    
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    margin-bottom: 35px;

    @media (min-width: 992px) {
        padding-top: 0;
    }

    &.center {
        justify-content: center !important;
    }

    &__extra-logos {
        display: flex;
        width: fit-content;
    }

    &__logo-img {
        height: auto;
        max-height: 50px;
        max-width: 50%;
        width: 150px;
    }

    &__accounts-logo {
        width: 100%;
    }

    &__link {
        max-width: 50%;
        width: 150px;

        &--disabled {
            pointer-events: none;
        }
    }

    &__divider {
        background: #555555;
        flex-shrink: 0;
        height: 30px;
        width: 1px;
    }
}
</style>
